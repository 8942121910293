import { useEffect, useState, useCallback } from "react";
import { Add, Remove } from "@material-ui/icons";
import "./pos.css";
import { useDispatch, useSelector } from "react-redux";
import { getProducts, getAccessories } from "../../redux/apiCalls";
import { userRequest } from "../../requestMethods";
import { publicRequest } from "../../requestMethods";
import styled from "styled-components";
import {
    addProduct,
    addQuantity,
    clearCart,
    addQuantityIcon,
} from "../../redux/cartRedux";
import Select from "react-select";
import createCustomerInvoice from "../../components/pdf/createCustomerInvoice";
import createDeposit from "../../components/pdf/createDeposit";

const ModalContainer = styled.div`
    height: 100%;
    width: 100%;
    opacity: 0.75;
    background: black;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: 10;
`;

const OverlayContainer = styled.div`
    overflow: hidden;
`;

const Overlay = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    color: white;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(white, 0.5);
    visibility: visible;
`;

const ModalDetails = styled.div`
    background-color: rgba(white, 0.5);
    color: black;
    height: 100%;
    width: 50%;
    display: flex;
    z-index: 102;
`;

const EmptyModalDetails = styled.div`
    background-color: rgba(white, 0.5);
    color: black;
    height: 100%;
    width: 50%;
    display: flex;
    z-index: 102;
`;

const ModalItemContainer = styled.div`
    background-color: white;
    color: black;
    height: 100%;
    width: 100%;
    display: grid;
`;

const ColorPickerContainer = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    height: 100vh;
    display: grid;
    grid-template-columns: auto auto;
    z-index: 101;
`;

const ColorPicker = styled.img`
    margin: 20px 10px;
    cursor: pointer;
    width: 90%;
    height: 30vh;
    object-fit: contain;
    background: white;
`;

export default function Pos() {
    const dispatch = useDispatch();
    const products = useSelector((state) => state.product.products);
    const [colorpicker, setColorPicker] = useState(false);
    const [cashierMode, setCashierMode] = useState("sales");
    const [usersData, setUsersData] = useState([]);
    const [cashierData, setCashierData] = useState([]);
    const [inputs, setInputs] = useState({
        isProduct: true,
        discount: 0,
        deposit: undefined,
    });
    const [warrantyList, setWarrantyList] = useState({});
    const [product, setProduct] = useState({});
    const [color, setColor] = useState({});
    const [filterList, setFilterList] = useState({
        type: "watch",
    });
    // eslint-disable-next-line
    const [productfilterCategory, setProductFilterCategory] = useState({
        status: "active",
        model: undefined,
    });

    // eslint-disable-next-line
    const [isProduct, setIsProduct] = useState([
        {
            label: "Product",
            value: true,
        },
        { label: "Warranty", value: false },
    ]);

    // eslint-disable-next-line
    const [paymentType, setPaymentType] = useState([
        {
            label: "Cash",
            value: "cash",
        },
        { value: "onlineTransfer", label: "Online Transfer" },
        { value: "card", label: "Debit / Credit Card" },
        { value: "noOneCard", label: "No One Card" },
        { value: "others", label: "Others" },
    ]);
    // eslint-disable-next-line
    const [quantity, setQuantity] = useState(1);

    const cart = useSelector((state) =>
        state.cart ? state.cart.products : []
    );

    const superadmin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isSuperAdmin : null
    );

    const principle = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.principle : null
    );

    const currentUser = useSelector((state) => state.user.currentUser);

    const NumberFormatter = new Intl.NumberFormat("en-GB", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const total = useSelector((state) =>
        state.cart ? (state.cart.total ? state.cart.total : 0) : 0
    );

    let deconstructedProductList = [];
    cart.forEach((product) => {
        for (let i = 0; i < product.quantity; i++) {
            deconstructedProductList.push(product);
        }
    });

    useEffect(() => {
        if (colorpicker === true) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "inherit";
        }
    }, [colorpicker]);

    useEffect(() => {
        if (usersData[0] === undefined) {
            getUsers();
        }
        dispatch(clearCart());
    }, [usersData, dispatch]);

    useEffect(() => {
        filterList.type === "watch"
            ? getProducts(dispatch, productfilterCategory)
            : getAccessories(dispatch, "");
    }, [dispatch, filterList, productfilterCategory]);

    const getUsers = async () => {
        const res = await publicRequest.get("/users/all", {
            params: {},
        });
        setUsersData(res.data);
    };

    const getCashiers = useCallback(async () => {
        const res = await publicRequest.get("/users/allstaff", {
            params: {
                principle:
                    principle && principle !== "64be53a291c85522064a711a"
                        ? principle
                        : undefined,
            },
        });
        setCashierData(res.data);
    }, [principle]);

    useEffect(() => {
        if (cashierData[0] === undefined) {
            getCashiers();
        }
        dispatch(clearCart());
    }, [getCashiers, cashierData, dispatch]);

    const handleChange = (e) => {
        setInputs((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    const handlePaymentType = (e) => {
        setInputs((prev) => {
            return { ...prev, paymentType: e.value };
        });
    };

    const handleIsProduct = (e) => {
        setInputs((prev) => {
            return { ...prev, isProduct: e.value };
        });
    };

    const handlePromoCode = (e) => {
        setInputs((prev) => {
            return { ...prev, affiliateCode: e };
        });
    };

    const handleWarrantyChange = (e, i) => {
        setWarrantyList((prev) => {
            return { ...prev, [i]: e.target.value };
        });
    };

    const handleInputUserId = (e) => {
        setInputs((prev) => {
            return { ...prev, userId: e.value };
        });
    };

    const handleCashier = (e) => {
        setInputs((prev) => {
            return { ...prev, cashier: e.value };
        });
    };

    const handleQuantity = (
        product,
        quantity,
        amount,
        myAmount,
        activeColor
    ) => {
        dispatch(
            addQuantityIcon({
                ...product,
                quantity,
                amount,
                myAmount,
                activeColor,
            })
        );
    };

    const handlePosCreateOrder = async () => {
        let totalItems = 0;
        cart.forEach((item) => {
            if (item.type === "watch") {
                totalItems = totalItems + 1 * item.quantity;
            }
        });
        if (cart.length === 0) {
            alert("No product added!!");
            return;
        }

        if (total - inputs.discount < 0) {
            alert("Total cannot be a negative number");
            return;
        }

        if (inputs.userId === undefined) {
            alert("Please select a customer first");
            return;
        }

        if (Object.keys(warrantyList).length !== totalItems) {
            alert(
                "Please enter the right amount of warranty for all the products"
            );
            return;
        }

        if (currentUser) {
            try {
                const createdBy = principle ? principle : currentUser._id;
                const res = await userRequest.post("/orders/pos", {
                    userId: createdBy,
                    inputs,
                    products: cart.map((item) => ({
                        productId: item._id,
                        quantity: item.quantity,
                        code: `${item.model}-${item.color.name}`,
                        img: item.color.img,
                    })),
                    amount: total,
                    address: "POS Sales",
                    warranty: warrantyList,
                    paymentType: inputs.paymentType,
                    isProduct: inputs.isProduct,
                    cashier: inputs.cashier ? inputs.cashier : currentUser._id,
                    affiliateCode: inputs.affiliateCode,
                });

                if (res.data) {
                    alert("Your order has been completed");
                    createCustomerInvoice(res.data);
                    dispatch(clearCart());
                } else {
                    alert("Server issue, please contact support");
                }
            } catch (err) {
                alert(
                    "Failed to create order. Please check if the warranty is correct and belongs to this outlet. Please contact admin if you need support."
                );
            }
        } else {
            alert("Please login before checking out");
        }
    };

    const handleClear = async () => {
        dispatch(clearCart());
    };

    const handlePosDeposit = async () => {
        let totalItems = 0;
        cart.forEach((item) => {
            if (item.type === "watch") {
                totalItems = totalItems + 1 * item.quantity;
            }
        });
        if (cart.length === 0) {
            alert("No product added!!");
            return;
        }

        if (total - inputs.discount < 0) {
            alert("Total cannot be a negative number");
            return;
        }

        if (inputs.userId === undefined) {
            alert("Please select a customer first");
            return;
        }

        if (total - inputs.deposit < 0) {
            alert("Deposit cannot be more than sales total");
            return;
        }

        if (!inputs.deposit) {
            alert(
                `Deposit cannot be less than ${
                    process.env.REACT_APP_COUNTRY === "SINGAPORE" ? "$" : "RM"
                } 0.00`
            );
            return;
        }

        if (currentUser) {
            try {
                const createdBy = principle ? principle : currentUser._id;
                const res = await userRequest.post("/orders/deposit", {
                    userId: createdBy,
                    inputs,
                    products: cart.map((item) => ({
                        productId: item._id,
                        quantity: item.quantity,
                        code: `${item.model}-${item.color.name}`,
                        img: item.color.img,
                    })),
                    amount: total,
                    address: "POS Sales",
                    isProduct: true,
                    cashier: currentUser ? currentUser._id : undefined,
                });

                if (res.data) {
                    alert("Your deposit has been completed");
                    createDeposit(res.data);
                    dispatch(clearCart());
                } else {
                    alert("Server issue, please contact support");
                }
            } catch (err) {
                alert("Failed to create deposit, please check with admin");
            }
        } else {
            alert("Please login before checking out");
        }
    };

    const handleAddProduct = (product, color) => {
        try {
            if (
                cart.find(
                    (item) =>
                        `${item.model}-${item.color.name}` ===
                        `${product.model}-${color.name}`
                ) === undefined
            ) {
                dispatch(
                    addProduct({ ...product, activeColor: color, quantity })
                );
            } else {
                dispatch(
                    addQuantity({ ...product, activeColor: color, quantity })
                );
            }
        } catch {}
    };

    return (
        <div className="pos">
            <div className="userContainer">
                <div className="checkoutButtons">
                    <div
                        className="salesButton lightyellow"
                        onClick={() => setCashierMode("sales")}
                    >
                        Sales
                    </div>
                    <div
                        className="summary totalButton pointer lightblue"
                        onClick={() => setCashierMode("deposit")}
                    >
                        Deposit
                    </div>
                </div>
                <div className="usersummaryContainer">
                    <div className="addProductItem fullWidth">
                        <label>Users</label>
                        <Select
                            options={usersData.map((user, i) => {
                                return {
                                    label: `${user.name}-${user.email}-${user.phoneNumber}`,
                                    value: user._id,
                                };
                            })}
                            onChange={handleInputUserId}
                        />
                    </div>
                    <div className="addProductItem fullWidth">
                        <label>Payment Type</label>
                        <Select
                            options={paymentType.map((paymentType, i) => {
                                return {
                                    label: paymentType.label,
                                    value: paymentType.value,
                                };
                            })}
                            onChange={handlePaymentType}
                        />
                    </div>

                    <div className="addProductItem fullWidth">
                        <label>Cashier</label>
                        <Select
                            options={cashierData.map((user, i) => {
                                return {
                                    label: `${user.name}-${user.email}-${user.phoneNumber}`,
                                    value: user._id,
                                };
                            })}
                            onChange={handleCashier}
                        />
                    </div>

                    {superadmin && (
                        <div className="addProductItem fullWidth">
                            <label>Sales Type</label>
                            <Select
                                placeholder={"Product"}
                                options={isProduct.map((paymentType, i) => {
                                    return {
                                        label: paymentType.label,
                                        value: paymentType.value,
                                    };
                                })}
                                onChange={handleIsProduct}
                            />
                        </div>
                    )}
                </div>
                <div className="calculator">
                    <div className="cartContainer">
                        <div className="cartTitle">Model</div>
                        <div className="cartTitle">Quantity</div>
                        <div className="cartTitle">Price</div>
                    </div>
                    {cart ? (
                        cart.map((product, i) => (
                            <div className="cartContainer" key={i}>
                                <div className="cartItem">
                                    {product.model}-{product.color.name}
                                </div>
                                <div className="quantityItem">
                                    <Add
                                        style={{ fontSize: "0.8rem" }}
                                        onClick={() =>
                                            handleQuantity(
                                                product,
                                                1,
                                                product.price,
                                                product.sgPrice,
                                                product.color
                                            )
                                        }
                                    />
                                    {product.quantity}
                                    <Remove
                                        style={{ fontSize: "0.8rem" }}
                                        onClick={() =>
                                            handleQuantity(
                                                product,
                                                -1,
                                                product.price -
                                                    product.price -
                                                    product.price,
                                                product.myprice -
                                                    product.myprice -
                                                    product.myprice,
                                                product.color
                                            )
                                        }
                                    />
                                </div>
                                <div className="cartItem">
                                    {process.env.REACT_APP_COUNTRY ===
                                    "SINGAPORE"
                                        ? "$"
                                        : "RM"}
                                    {product.price}
                                </div>
                            </div>
                        ))
                    ) : (
                        <></>
                    )}
                </div>
                <div className="summary">
                    <div className="summarysubtotal">SubTotal </div>
                    <div className="summarysubtotal">
                        {process.env.REACT_APP_COUNTRY === "SINGAPORE"
                            ? "$"
                            : "RM"}
                        {total ? NumberFormatter.format(total) : "0"}
                    </div>
                </div>

                <div className="summary depositPadding">
                    <div className="addDiscountItem">
                        <div className="summarysubtotal">Discount</div>
                        <input
                            name="discount"
                            type="number"
                            onChange={handleChange}
                            defaultValue={""}
                        />
                    </div>
                </div>

                {cashierMode === "sales" && (
                    <>
                        <div className="summary totalButton">
                            <div className="summarysubtotal">Total </div>
                            <div className="summarysubtotal">
                                {process.env.REACT_APP_COUNTRY === "SINGAPORE"
                                    ? "$"
                                    : "RM"}
                                {inputs.discount
                                    ? NumberFormatter.format(
                                          total - inputs.discount
                                      )
                                    : NumberFormatter.format(total)}
                            </div>
                        </div>
                        {deconstructedProductList.map((product, i) => {
                            if (product.type === "watch") {
                                return (
                                    <div className="warrantyContainer" key={i}>
                                        <div className="warrantyItem">
                                            {product.model}-{product.color.name}
                                        </div>
                                        <div className="warrantyItem paddingX black">
                                            <input
                                                name="warranty"
                                                type="string"
                                                onChange={(e) =>
                                                    handleWarrantyChange(e, i)
                                                }
                                                defaultValue={""}
                                            />
                                        </div>
                                    </div>
                                );
                            }
                            return undefined;
                        })}
                        <div className="summary lightred noBorder ">
                            <div className="warrantyItem black">Gift Card</div>
                            <div className="warrantyItem paddingX">
                                <input
                                    name="warranty"
                                    type="string"
                                    onChange={(e) =>
                                        handlePromoCode(e.target.value)
                                    }
                                    defaultValue={""}
                                />
                            </div>
                        </div>
                        <div className="checkoutButtons">
                            <div
                                className="salesButton sales"
                                onClick={handlePosCreateOrder}
                            >
                                Sales{" "}
                            </div>
                        </div>
                        <div className="checkoutButtons">
                            <div
                                className="salesButton reset"
                                onClick={handleClear}
                            >
                                Reset{" "}
                            </div>
                        </div>
                        {/* <div
                            className="summary totalButton pointer lightblue"
                            onClick={() => handleWhatsapp()}
                        >
                            Whatsapp
                        </div> */}
                    </>
                )}

                {cashierMode === "deposit" && (
                    <>
                        <div className="summary depositPadding">
                            <div className="addDiscountItem">
                                <div className="summarysubtotal">Deposit</div>
                                <input
                                    name="deposit"
                                    type="number"
                                    onChange={handleChange}
                                    defaultValue={""}
                                />
                            </div>
                        </div>
                        <div className="summary totalButton">
                            <div className="summarysubtotal">Remaining </div>
                            <div className="summarysubtotal">
                                {process.env.REACT_APP_COUNTRY === "SINGAPORE"
                                    ? "$"
                                    : "RM"}
                                {NumberFormatter.format(
                                    inputs.discount && inputs.deposit
                                        ? total -
                                              inputs.discount -
                                              inputs.deposit
                                        : inputs.discount
                                        ? total - inputs.discount
                                        : inputs.deposit
                                        ? total - inputs.deposit
                                        : total
                                )}
                            </div>
                        </div>
                        <div className="">
                            <div
                                className="salesButton deposit"
                                onClick={handlePosDeposit}
                            >
                                Deposit{" "}
                            </div>
                        </div>
                    </>
                )}
            </div>

            {colorpicker && (
                <OverlayContainer>
                    <ModalContainer></ModalContainer>
                    <Overlay>
                        <EmptyModalDetails
                            onClick={() => setColorPicker(false)}
                        ></EmptyModalDetails>
                        <ModalDetails>
                            <ModalItemContainer>
                                <ColorPickerContainer>
                                    {color
                                        ? color
                                              .filter(
                                                  (c) => c.status === "active"
                                              )
                                              .map((c, i) => (
                                                  <ColorPicker
                                                      key={i}
                                                      src={c.img}
                                                      onClick={() => {
                                                          setColorPicker(false);
                                                          handleAddProduct(
                                                              product,
                                                              c
                                                          );
                                                      }}
                                                  />
                                              ))
                                        : ""}
                                </ColorPickerContainer>
                            </ModalItemContainer>
                        </ModalDetails>
                    </Overlay>
                </OverlayContainer>
            )}
            <div className="posWrapper">
                <div className="checkoutButtons">
                    <div
                        className="salesButton lightred"
                        onClick={() => setFilterList({ type: "watch" })}
                    >
                        Watches
                    </div>
                    <div
                        className="summary totalButton pointer beige"
                        onClick={() => setFilterList({ type: "accessory" })}
                    >
                        Accessories
                    </div>
                </div>
                <div className="posContainer">
                    {" "}
                    {products.map((product, i) => {
                        if (product.type === filterList.type) {
                            return (
                                <div
                                    key={i}
                                    className="posItem"
                                    onClick={() => {
                                        setColor(product.color);
                                        setProduct(product);
                                        window.scrollTo(0, 0);
                                        setColorPicker(true);
                                    }}
                                >
                                    <div className="justify-center flex">
                                        <img
                                            src={
                                                product.color
                                                    ? product.color[0].img
                                                    : ""
                                            }
                                            alt="productImage"
                                            className="posImg"
                                        />
                                    </div>
                                    <div className="modelName">
                                        {product.model}
                                    </div>
                                </div>
                            );
                        }
                        return true;
                    })}
                </div>
            </div>
        </div>
    );
}
