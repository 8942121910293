import Sidebar from "./components/sidebar/Sidebar";
import Topbar from "./components/topbar/Topbar";
import "./App.css";
import Home from "./pages/home/Home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import User from "./pages/user/User";
import NewUser from "./pages/newUser/NewUser";
import ProductList from "./pages/productList/ProductList";
import Product from "./pages/product/Product";
import ProductColor from "./pages/productColor/ProductColor";
import ProductGallery from "./pages/productGallery/ProductGallery";
import NewProduct from "./pages/newProduct/NewProduct";
import WarrantyList from "./pages/warrantyList/WarrantyList";
import ReturnList from "./pages/warrantyList/ReturnList";
import DesStockList from "./pages/warrantyList/DesStockList";
import NewWarranty from "./pages/newWarranty/NewWarranty";
import NewAdmin from "./pages/newAdmin/NewAdmin";
import NewColor from "./pages/newProduct/NewColor";
import ColorList from "./pages/colorList/ColorList";
import NewAffiliate from "./pages/newAffiliate/NewAffiliate";
import OrderList from "./pages/orderList/OrderList";
import AffiliateList from "./pages/affiliateList/AffiliateList";
import History from "./pages/history/History";
import DepositList from "./pages/depositList/DepositList";
import EcommerceList from "./pages/ecommerceList/EcommerceList";
import SalesReport from "./pages/salesReport/SalesReport";
import Report from "./pages/report/Reporting";
import Login from "./pages/login/Login";
import Pos from "./pages/pos/pos";
import Outlet from "./pages/outlet/Outlet";
import { useSelector } from "react-redux";
import TransactionList from "./pages/transaction/TransactionList";
import ProductReport from "./pages/productReport/ProductReport";
import ProductStatus from "./pages/productStatus/ProductStatus";
import AffiliateReport from "./pages/affiliateReport/AffiliateReport";
import Checklist from "./pages/checkList/CheckList";
import Campaign from "./pages/campaignList/Campaign";
import Transfer from "./pages/transfer/Transfer";
import DeliveryOrder from "./pages/deliveryOrderList/DeliveryOrder";
import DeliveryOrderUpload from "./pages/deliveryOrderUpload/DeliveryOrderUpload";
import CompleteDepositList from "./pages/completeDepositList/CompleteDepositList";
import ModelReport from "./pages/productReport/ModelReport";
import TransferHistory from "./pages/transaction/TransferHistory";
import CommissionList from "./pages/commissionList/CommissionList";
import DailyReport from "./pages/report/DailyReport";
import NewSlider from "./pages/newProduct/NewSlider";
import SliderList from "./pages/sliderList/SliderList";
import NewAccessory from "./pages/newProduct/NewAccessory";
import NewStaff from "./pages/newUser/NewStaff";

function App() {
    const admin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isAdmin : null
    );
    const superadmin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isSuperAdmin : null
    );

    const staff = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.type === "staff" : null
    );

    const manager = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "manager"
            : null
    );

    const marketing = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "marketing"
            : null
    );

    const accountant = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "accountant"
            : null
    );

    return (
        <div className="globalContainer">
            <Router>
                <Switch>
                    <Route exact path="/">
                        {admin ||
                        superadmin ||
                        staff ||
                        manager ||
                        accountant ||
                        marketing ? (
                            <Home />
                        ) : (
                            <Login />
                        )}
                    </Route>
                    {(staff ||
                        manager ||
                        admin ||
                        superadmin ||
                        accountant ||
                        marketing) && (
                        <>
                            <Topbar />
                            <div className="container">
                                <Sidebar />
                                <Route path="/orders">
                                    <OrderList />
                                </Route>
                                <Route path="/commissions">
                                    <CommissionList />
                                </Route>
                                <Route path="/completedeposit">
                                    <CompleteDepositList />
                                </Route>
                                <Route path="/history">
                                    <History />
                                </Route>
                                <Route path="/deposits">
                                    <DepositList />
                                </Route>
                                <Route path="/completeorder">
                                    <EcommerceList />
                                </Route>
                                {(staff ||
                                    manager ||
                                    admin ||
                                    superadmin ||
                                    marketing) && (
                                    <>
                                        <Route exact path="/pos">
                                            <Pos />
                                        </Route>
                                        <Route path="/newUser">
                                            <NewUser />
                                        </Route>
                                        <Route path="/deliveryorders">
                                                                <DeliveryOrder />
                                                            </Route>
                                        {(manager ||
                                            admin ||
                                            superadmin ||
                                            marketing) && (
                                            <>
                                                <Route path="/warranties">
                                                    <WarrantyList />
                                                </Route>
                                                <Route path="/returns">
                                                    <ReturnList />
                                                </Route>
                                                <Route path="/stocks">
                                                    <DesStockList />
                                                </Route>
                                                <Route path="/checklist">
                                                    <Checklist />
                                                </Route>
                                                <Route path="/transfer">
                                                    <Transfer />
                                                </Route>
                                                {(superadmin || admin || marketing) && (
                                                    <>
                                                        <Route path="/products">
                                                            <ProductList />
                                                        </Route>
                                                        <Route path="/newproduct">
                                                            <NewProduct />
                                                        </Route>
                                                        <Route path="/newcolor">
                                                            <NewColor />
                                                        </Route>
                                                        <Route path="/colors">
                                                            <ColorList />
                                                        </Route>
                                                        <Route path="/newslider">
                                                            <NewSlider />
                                                        </Route>
                                                        <Route path="/sliders">
                                                            <SliderList />
                                                        </Route>
                                                        {superadmin && (
                                                            <>
                                                                <Route path="/outlets">
                                                                    <Outlet />
                                                                </Route>
                                                                <Route path="/newwarranty">
                                                                    <NewWarranty />
                                                                </Route>
                                                                <Route path="/transactions">
                                                                    <TransactionList />
                                                                </Route>

                                                                <Route path="/transferhistory">
                                                                    <TransferHistory />
                                                                </Route>

                                                                <Route path="/newadmin">
                                                                    <NewAdmin />
                                                                </Route>

                                                                <Route path="/newstaff">
                                                                    <NewStaff />
                                                                </Route>
                                                        
                                                            </>
                                                        )}
                                                        {(superadmin ||
                                                            marketing) && (
                                                            <>
                                                                <Route path="/users">
                                                                    <User />
                                                                </Route>
                                                                <Route path="/campaigns">
                                                                    <Campaign />
                                                                </Route>
                                                                <Route path="/affiliates">
                                                                    <AffiliateList />
                                                                </Route>
                                                                <Route path="/newaffiliate">
                                                                    <NewAffiliate />
                                                                </Route>
                                                                <Route path="/newaccessory">
                                                            <NewAccessory />
                                                        </Route>
                                                                <Route path="/productgallery/:productId">
                                                                    <ProductGallery />
                                                                </Route>
                                                                <Route path="/productstatus/:productId">
                                                                    <ProductStatus />
                                                                </Route>
                                                                <Route path="/product/:productId">
                                                                    <Product />
                                                                </Route>
                                                                <Route path="/deliveryorderupload/:deliveryOrderId">
                                                                    <DeliveryOrderUpload />
                                                                </Route>
                                                                <Route path="/salesreport">
                                                                    <SalesReport />
                                                                </Route>
                                                                <Route path="/report">
                                                                    <Report />
                                                                </Route>
                                                                <Route path="/dailyreport">
                                                                    <DailyReport />
                                                                </Route>
                                                                <Route path="/productreport">
                                                                    <ProductReport />
                                                                </Route>
                                                                <Route path="/modelreport">
                                                                    <ModelReport />
                                                                </Route>
                                                                <Route path="/productcolor/:productId">
                                                                    <ProductColor />
                                                                </Route>
                                                                <Route path="/affiliatereport">
                                                                    <AffiliateReport />
                                                                </Route>
                                                            </>
                                                        )}

                                                    </>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </>
                    )}
                </Switch>
            </Router>
        </div>
    );
}

export default App;
